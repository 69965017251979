import React, {useState} from "react";
import {
    Input, FormField, SpaceBetween, Button, Alert
} from "@cloudscape-design/components";
import {ApiHelper} from "../../Helpers/apiHelper";
import AuthConnectorProps from "./ConnectorTypes";
import CustomerIdSelector from "../CustomerIdSelector";
import {cloudcallIdValidator} from "../../Helpers/validator";

const connectorName = "AccessRecruitment";

const SetAccessRecruitmentAuth = ({ setNotifications, clearNotifications, setIsLoading, connectorId }: AuthConnectorProps) => {

    const [apiHelper] = useState(ApiHelper())
    const [apiKey, setAPIKey] = useState("");
    const [integratorKey, setIntegratorKey] = useState("");
    const [clientId, setClientId] = useState("");
    const [apiUrl, setAPIUrl] = useState("");
    const [webhookUrl, setWebhookUrl] = useState("");
    const [customerId, setCustomerId] = useState("");

    const getButtonIsDisabled = () => {
        let additionalBodyIsInvalid = !apiKey || apiKey.length === 0 || 
                !integratorKey || integratorKey.length === 0 || 
                !clientId || clientId.length === 0
                !apiUrl || apiUrl.length === 0
                !webhookUrl || webhookUrl.length === 0
                
        return (additionalBodyIsInvalid || customerId.length === 0 || !cloudcallIdValidator(customerId));
    }

    const submitAuth = async () => {
        setIsLoading(true);
        clearNotifications();
        const existingConnectors = await apiHelper.makeSignedRequest(`api/clientConnectors?customerId=${customerId}`)
            .then((res) => {
                return res?.data.filter((connector: any) => {
                    return connector?.clientConfiguration?.integrationName === connectorName
                });
            })

        const isUpdate = existingConnectors.length > 0;
        let targetUrl = `api/connectors/v1/oauth/authenticate?connectorName=${connectorName}&customerId=${customerId}&connectorId=${connectorId}`;
        let customerMessage = `${connectorName} authentication credentials submitted`;
        if (isUpdate) {
            const targetIntegrationId = existingConnectors[0].id;
            targetUrl = `${targetUrl}&existingConnectionId=${targetIntegrationId}`
            customerMessage = `${connectorName} authentication credentials updated`;
        }
        apiHelper.makeSignedRequest(targetUrl, "POST", {
            customerId,
            apiKey,
            integratorKey,
            clientId,
            apiUrl,
            webhookUrl,
        }, {
            "X-CC-CustomerId": customerId
        }).then((res) => {
            setNotifications(<Alert
                statusIconAriaLabel="Info"
                dismissible
                //@ts-ignore
                onDismiss={clearNotifications}
                //@ts-ignore
                type="success"
                header="Request Successful"
                key={"success"}
            >
                <SpaceBetween size={"xs"}>
                    <div key={"title"}>
                        {customerMessage}
                    </div>
                </SpaceBetween>
            </Alert>)
        })
            .catch((e) => {
                let message = "An error occurred fetching the url, please check the values entered or refresh and try again";
                let header = "Invalid Request"
                if (e.response?.status === 401) {
                    header = "Invalid apiKey or integratorKey"
                    message = "The submitted credentials were rejected by Access, please check them and try again"
                }
                setNotifications(<Alert
                    statusIconAriaLabel="Error"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="error"
                    header={header}
                    key={"errorFetching"}
                >
                    {message}
                </Alert>)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    return <>
        <SpaceBetween size={"m"} >
            <FormField
                description="Customer ID"
            >
                <CustomerIdSelector
                    initialValue={customerId}
                    onChange={setCustomerId}
                    invalid={!cloudcallIdValidator(customerId)}
                />
            </FormField>
            <FormField
                description="API Key"
            >
                <Input value={apiKey} onChange={({ detail }) => {
                    setAPIKey(detail.value)
                }} />
            </FormField>
            <FormField
                description="Integrator Key"
            >
                <Input value={integratorKey} onChange={({ detail }) => {
                    setIntegratorKey(detail.value)
                }} />
            </FormField>
            <FormField
                description="Client Id"
            >
                <Input value={clientId} onChange={({ detail }) => {
                    setClientId(detail.value)
                }} />
            </FormField>
            <FormField
                description="API URL"
            >
                <Input value={apiUrl} onChange={({ detail }) => {
                    setAPIUrl(detail.value)
                }} />
            </FormField>
            <FormField
                description="Webhook URL"
            >
                <Input value={webhookUrl} onChange={({ detail }) => {
                    setWebhookUrl(detail.value)
                }} />
            </FormField>
            <Button
                variant={"primary"}
                disabled={getButtonIsDisabled()}
                onClick={()=>submitAuth()}>
                Submit Authentication Credentials
            </Button>
        </SpaceBetween>
    </>

}

export default SetAccessRecruitmentAuth;
