import React, {useState} from "react";
import {
    Alert,
    Box,
    Button, Checkbox, CopyToClipboard, FormField, Input,
    Link, Modal,
    SpaceBetween
} from "@cloudscape-design/components";
import {ApiHelper} from "../../Helpers/apiHelper";
import {cloudcallIdValidator} from "../../Helpers/validator";
import CustomerIdSelector from "../CustomerIdSelector";
import AuthConnectorProps from "./ConnectorTypes";



const connectorName = "Vincere";

const GetVincereAuthUrl = ({ setNotifications, clearNotifications, setIsLoading, connectorId }: AuthConnectorProps ) => {
    const [apiHelper] = useState(ApiHelper())
    const [customerId, setCustomerId] = useState("");
    const [clientID, setClientID] = useState("");
    const [tenantId, setTenantId] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [tenantIdError, setTenantIdError] = useState("");

    const getButtonIsDisabled = () => {
        return (
            customerId.length === 0 || 
            !cloudcallIdValidator(customerId) ||
            (tenantId.length > 0 && !isValidDomain(tenantId)) ||
            clientID.length === 0 ||
            tenantId.length === 0 ||
            apiKey.length === 0
        );
    }

    const isValidDomain = (domain: string) => {
        // Match domain pattern without protocol
        const domainPattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        return domainPattern.test(domain);
    };

    const getUrl = async () => {
        setIsLoading(true);
        clearNotifications();
        const existingConnectors = await apiHelper.makeSignedRequest(`api/clientConnectors?customerId=${customerId}`)
            .then((res) => {
                return res?.data.filter((connector: any) => {
                    return connector?.clientConfiguration?.integrationName === connectorName
                });
            })
        const isUpdate = existingConnectors.length > 0;
        let targetUrl = `api/connectors/v1/oauth/login?connectorName=${connectorName}`;
        let customerMessage = `Please send the following url to the customer to create a new ${connectorName} integration:`;
        if (isUpdate) {
            const targetIntegrationId = existingConnectors[0].id;
            targetUrl = `${targetUrl}&existingConnectionId=${targetIntegrationId}`
            customerMessage = `Please send the following url to the customer to update their existing ${connectorName} integration:`;
        } else if (!clientID || clientID.length === 0 || !tenantId || tenantId.length === 0 || !apiKey || apiKey.length === 0) {
            setNotifications(<Alert
                statusIconAriaLabel="Error"
                dismissible
                //@ts-ignore
                onDismiss={clearNotifications}
                //@ts-ignore
                type="error"
                header="Error"
                key={"requireParamsNotSet"}
            >
                For new integrations, Client ID, Tenant ID and API Key are all required
            </Alert>);
            setIsLoading(false);
            return
        }
        apiHelper.makeSignedRequest(targetUrl, "POST", {
            "customerId": customerId,
            "vincereClientId": clientID.length > 0 ? clientID : undefined,
            "vincereTenantId": tenantId.length > 0 ? tenantId : undefined,
            "vincereApiKey": apiKey.length > 0 ? apiKey : undefined

        }).then((res) => {
            let error = (
                <Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="error"
                    header="Error"
                    key={"errorFetching"}
                >
                    An error occurred fetching the url, please check the values entered or refresh and try again
                </Alert>
            )
            if (res && res.data.redirectUrl) {
                error = (<Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="success"
                    header="Request Successful"
                    key={"success"}
                >
                    <SpaceBetween size={"xs"}>
                        <div key={"title"}>
                            {customerMessage}
                        </div>
                        <CopyToClipboard
                            copyButtonText="Copy"
                            copyErrorText="URL failed to copy"
                            copySuccessText="URL copied"
                            variant={"inline"}
                            //@ts-ignore
                            textToCopy={res.data.redirectUrl}
                            key={"copy"}
                        />
                    </SpaceBetween>
                </Alert>)
            }
            setNotifications(error);
        }).catch((e) => {
            let message = "An error occurred fetching the url, please check the values entered or refresh and try again";
            let header = "Invalid Request"
            setNotifications(<Alert
                statusIconAriaLabel="Error"
                dismissible
                //@ts-ignore
                onDismiss={clearNotifications}
                //@ts-ignore
                type="error"
                header={header}
                key={"errorFetching"}
            >
                {message}
            </Alert>)
        })
            .finally(() => {
                setIsLoading(false)
            })
    };

    return (
        <>
            <SpaceBetween size={"m"} >
                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <FormField
                    description="Vincere Client ID"
                >
                    <Input value={clientID} onChange={({ detail }) => {
                        setClientID(detail.value)
                    }} />
                </FormField>
                <FormField
                    description="Vincere Tenant ID (domain name only, e.g. example.com)"
                    errorText={tenantIdError}
                >
                    <Input 
                        value={tenantId} 
                        invalid={!!tenantIdError}
                        onChange={({ detail }) => {
                            const value = detail.value;
                            setTenantId(value);
                            if (value && !isValidDomain(value)) {
                                setTenantIdError("Please enter a valid domain name without http:// (e.g. example.com)");
                            } else {
                                setTenantIdError("");
                            }
                        }} 
                    />
                </FormField>
                <FormField
                    description="Vincere API Key"
                >
                    <Input value={apiKey} type={"password"} onChange={({ detail }) => {
                        setApiKey(detail.value)
                    }} />
                </FormField>
                <Button
                    variant={"primary"}
                    disabled={getButtonIsDisabled()}
                    onClick={()=>getUrl()}>
                    Get Auth URL
                </Button>
            </SpaceBetween>
        </>
    )

}

export default GetVincereAuthUrl;
