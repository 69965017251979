import GetSalesforceAuthUrl from "./GetSalesforceAuthUrl";
import SetBullhornAuth from "./SetBullhornAuth";
import GetVincereAuthUrl from "./GetVincereAuthUrl";
import GetTrackerAuthUrl from "./GetTrackerAuthUrl";
import SetLaboredgeAuth from "./SetLaboredgeAuth";
import SetAccessRecruitmentAuth from "./SetAccessRecruitmentAuth";


const AvailableConnectors = {
    Salesforce: GetSalesforceAuthUrl,
    Bullhorn: SetBullhornAuth,
    Vincere: GetVincereAuthUrl,
    TrackerRms: GetTrackerAuthUrl,
    Laboredge: SetLaboredgeAuth,
    AccessRecruitment: SetAccessRecruitmentAuth,
}

export default AvailableConnectors;
